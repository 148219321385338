var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center","align-v":"center"}},[_c('b-col',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":_vm.form.productType == 'expense' ? 4 : 12}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.type')}},[_c('b-form-radio-group',{attrs:{"options":_vm.options.type,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.productType),callback:function ($$v) {_vm.$set(_vm.form, "productType", $$v)},expression:"form.productType"}})],1)],1),(_vm.form.productType == 'expense')?_c('b-col',{attrs:{"md":"8"}},[_c('b-alert',{staticClass:"mb-0",attrs:{"show":"","dismissible":"","fade":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Expenses type are for additional charge like Freight Charge when filling Purchase Order ")])])])],1):_vm._e(),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"sm","placeholder":_vm.$t('globalSingular.auto'),"disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","size":"sm","state":errors.length > 0 ? false : null,"placeholder":!_vm.actions.isPreview
                        ? _vm.$t('apps.masterDataProduct.placeholder.name')
                        : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.unitofmeasure'),"label-for":"unitofmeasure"}},[_c('validation-provider',{attrs:{"name":"unitofmeasure","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"product-unitofmeasure","options":_vm.LOV.uom,"reduce":function (field) { return field.id; },"label":"uom","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.placeholder.unit')  : null},model:{value:(_vm.form.uomId),callback:function ($$v) {_vm.$set(_vm.form, "uomId", $$v)},expression:"form.uomId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.brand'),"label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"brand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"product-brand","options":_vm.LOV.brand,"reduce":function (field) { return field.id; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.placeholder.brand')  : null},model:{value:(_vm.form.brandId),callback:function ($$v) {_vm.$set(_vm.form, "brandId", $$v)},expression:"form.brandId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.category'),"label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"product-category","options":_vm.LOV.category,"reduce":function (field) { return field.id; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.placeholder.category')  : null},on:{"option:selected":_vm.categoryChange},model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.subCategory'),"label-for":"subCategory"}},[_c('validation-provider',{attrs:{"name":"subCategory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"product-category","options":_vm.LOV.subCategory,"reduce":function (field) { return field.id; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.placeholder.category')  : null},model:{value:(_vm.form.subCategoryId),callback:function ($$v) {_vm.$set(_vm.form, "subCategoryId", $$v)},expression:"form.subCategoryId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.isInventory')}},[_c('b-form-checkbox',{staticClass:"mr-0 custom-control-inline",attrs:{"options":_vm.options.inventory,"value":true,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.inventoryType),callback:function ($$v) {_vm.$set(_vm.form, "inventoryType", $$v)},expression:"form.inventoryType"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('apps.masterDataProduct.singular.isInventory')))])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('hr',{staticClass:"my-2"})]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mr-0 custom-control-inline",attrs:{"options":_vm.options.isBuy,"value":true,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.isBuying),callback:function ($$v) {_vm.$set(_vm.form, "isBuying", $$v)},expression:"form.isBuying"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('apps.masterDataProduct.singular.isBuying')))])],1),_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.buyingAmount'),"label-for":"buyingAmount"}},[_c('validation-provider',{attrs:{"name":"buyingamount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"id":"buyingAmount","raw":true,"options":_vm.options.number,"placeholder":"Sugestion Price, ex : 500.000","disabled":_vm.actions.isPreview},model:{value:(_vm.form.buyingAmount),callback:function ($$v) {_vm.$set(_vm.form, "buyingAmount", _vm._n($$v))},expression:"form.buyingAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.buyingAccount'),"label-for":"buyingAccount"}},[_c('validation-provider',{attrs:{"name":"buyingaccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"buyingAccount","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.singular.buyingAccount')  : null},model:{value:(_vm.form.buyingAccountId),callback:function ($$v) {_vm.$set(_vm.form, "buyingAccountId", $$v)},expression:"form.buyingAccountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.buyingReturnAccount'),"label-for":"buyingReturnAccount"}},[_c('validation-provider',{attrs:{"name":"buyingreturnaccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"buyingReturnAccount","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.singular.buyingReturnAccount')  : null},model:{value:(_vm.form.buyingReturAccountId),callback:function ($$v) {_vm.$set(_vm.form, "buyingReturAccountId", $$v)},expression:"form.buyingReturAccountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"mr-0 custom-control-inline",attrs:{"options":_vm.options.isSell,"value":true,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.isSell),callback:function ($$v) {_vm.$set(_vm.form, "isSell", $$v)},expression:"form.isSell"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('apps.masterDataProduct.singular.isSelling')))])],1),_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.sellingAmount'),"label-for":"sellingAmount"}},[_c('validation-provider',{attrs:{"name":"sellinggamount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control form-control-sm",attrs:{"id":"sellingAmount","raw":true,"options":_vm.options.number,"placeholder":"Sugestion Sell Price, ex : 100,000","disabled":_vm.actions.isPreview},model:{value:(_vm.form.sellAmount),callback:function ($$v) {_vm.$set(_vm.form, "sellAmount", $$v)},expression:"form.sellAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.sellingAccount'),"label-for":"sellingAccount"}},[_c('validation-provider',{attrs:{"name":"sellingaccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"sellingAccount","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.singular.buyingAccount')  : null},model:{value:(_vm.form.sellAccountId),callback:function ($$v) {_vm.$set(_vm.form, "sellAccountId", $$v)},expression:"form.sellAccountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataProduct.singular.sellingReturnAccount'),"label-for":"sellingReturnAccount"}},[_c('validation-provider',{attrs:{"name":"sellingreturnaccount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"sellingReturnAccount","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataProduct.singular.buyingReturnAccount')  : null},model:{value:(_vm.form.sellReturAccountId),callback:function ($$v) {_vm.$set(_vm.form, "sellReturAccountId", $$v)},expression:"form.sellReturAccountId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-master-data-product-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.backToList"))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.delete"))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t("globalActions.update") : _vm.$t("globalActions.create"))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t("globalActions.changeToEdit"))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }